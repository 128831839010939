:root {
    --webgOrange: #72B261;
    --webgTextGreen: #20d489;

    --webgColorDark: #202223;

    --webgBorderLight: #e9ecee;
    --webgBorderDark: #c9cccf;
}

.menu-state-title-primary .menu-item .menu-link.active .menu-title,
.menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title,
.menu-state-title-primary .menu-item.hover>.menu-link:not(.disabled):not(.active) .menu-title {
    color: var(--webgOrange);
}

.menu-title-gray-700 .menu-item .menu-link .menu-title i {
    color: inherit;
}

.btn.btn-webgOrange {
    color: #fff;
    border-color: var(--webgOrange);
    background-color: var(--webgOrange);
}

.btn.btn-webgOrange:hover:not(.btn-active) {
    border-color: #72B261;
    background-color: #72B261;
}

.text-warning {
    color: var(--webgOrange) !important;
}

.dashboardMain .symbol-label>span {
    font-size: 32px !important;
}

.btn-check:active+.btn.btn-primary,
.btn-check:checked+.btn.btn-primary,
.btn.btn-primary.active,
.btn.btn-primary.show,
.btn.btn-primary:active:not(.btn-active),
.btn.btn-primary:focus:not(.btn-active),
.btn.btn-primary:hover:not(.btn-active),
.show>.btn.btn-primary {
    border-color: #72B261 !important;
    background-color: #72B261 !important;
}

/* .searchHeadMain input {
    width: 115px;
}

.searchHeadMain input:focus {
    background-color: #fff !important;
    width: auto;
} */
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active),
.menu-state-bg-light-primary .menu-item.hover>.menu-link:not(.disabled):not(.active) {
    background-color: #fff6eb;
}

.paginate_button .page-link:hover,
.page-link:focus {
    background-color: #fff8dd;
}

.tooltip {
    z-index: 109 !important;
}

.text-green {
    color: var(--webgTextGreen) !important;
}

.badge-light-green {
    color: var(--webgTextGreen) !important;
    background-color: #e4fff4 !important;
}

.tox-statusbar {
    display: none !important;
}

.stepper.stepper-pills .stepper-item .stepper-icon,
.stepper.stepper-pills .stepper-item.completed .stepper-icon,
.stepper.stepper-pills .stepper-item.current:last-child .stepper-icon {
    background-color: #fff6eb;
}

.btn.btn-active-light-primary:hover:not(.btn-active),
.btn.btn-active-light-primary:focus:not(.btn-active) {
    background-color: #fff6eb !important;
}

.topStickyBar a {
    text-decoration: underline;
}

.topStickyBar a:hover {
    text-decoration: underline;
    opacity: 0.8;
}

.file-upload-W9 {
    position: relative;
    display: inline-block;
}

.file-upload-W9__label {
    display: block;
    border-radius: 0.4em;
    transition: 0.3s;
}

.file-upload-W9__label:hover {
    cursor: pointer;
}

.file-upload-W9__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}

.table .menu-item .menu-link {
    max-width: fit-content;
}

.fbcBoxInner {
    position: relative;
    overflow: hidden;
}

.fbcBoxSettings {
    position: absolute;
    right: 6px;
    top: 6px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100px);
    transition: all ease-in-out 0.3s;
}

.fbcBoxInner:hover .fbcBoxSettings {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}

.fbCanvas {
    border: 2px dashed #f5f8fa;
    padding: 20px;
    border-radius: 15px;
}

.fbList {
    margin-top: 60px;
}

.fbList .list-group-item {
    border-color: rgb(245 248 250);
}

html {
    scroll-behavior: smooth;
}

.reportCard .card-header {
    min-height: 50px;
}

.qrCode {
    max-width: 104px;
}

.qrCodeInner {
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.qrCodeInner i {
    font-size: 36px;
    color: #fff;
}

.qrCodeInner span {
    font-size: 11px;
    color: #fff;
    display: block !important;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
}

span.downloadQRCode {
    display: block;
    text-align: right;
}

span.downloadQRCode b {
    text-decoration: underline;
}

span.downloadQRCode b:hover {
    color: #000;
    text-decoration: none;
}

.topStickyBarNONE {
    transform: translateY(-10px);
    position: absolute;
    left: 0;
    right: 0;
    transition: all ease-in-out 0.2s;
    opacity: 0;
}

.topStickyBarHide {
    position: absolute;
    top: 12px;
    font-size: 20px;
    line-height: normal;
    color: #fff;
    right: 10px;
    cursor: pointer;
    opacity: 0.8;
}

.topStickyBarHide:hover {
    opacity: 1;
}

.header a,
.header .btn span,
.header .btn {
    white-space: nowrap !important;
}

.card-header h3.text-dark {
    font-size: 20px;
}

.tableSettingBtn {
    display: none;
}

.paymentPageTabs .nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link {
    font-size: 15px;
    font-weight: 500;
    padding-top: 0;
}

.right-0 {
    right: 0;
}

.top-0 {
    top: 0;
}

.xlTabDesign .nav-line-tabs {
    display: flex;
    width: 100%;
    text-align: center;
}

.xlTabDesign .nav-line-tabs li {
    width: 50%;
}

.xlTabDesign .nav-line-tabs .nav-item .nav-link {
    padding: 14px;
    border: none !important;
}

.xlTabDesign .nav-line-tabs .nav-item .nav-link.active {
    background: #f5f8fa;
    color: #222 !important;
}

.addNewReferredByBox,
.srDetailBox {
    display: none;
}

.addNewReferredByBox.showBox,
.srDetailBox.showBox,
.almStepsBox.showBox {
    display: block !important;
}

.addNewReferredByBox,
.srDetailBox {
    display: none;
}

.addNewReferredByBox.showBox,
.srDetailBox.showBox {
    display: block;
}

/* features removed start */
/* .dashboardMain .card-toolbar button.btn.btn-sm {
    display: none;
}

.dashboardMain .card-toolbar span.badge {
    margin-right: 0 !important;
}

.dashboardMain th.bg-light-primary.text-dark,
.dashboardMain td.bg-light-primary.text-dark {
    background: inherit !important;
    color: inherit !important;
}

.paymentPageTabs a>span.badge {
    color: inherit !important;
    background-color: #f5f8fa !important;
} */

/* features removed end */

.textGold {
    color: #d4af37;
}

.textSilver {
    color: #a8a9ad;
}

.textBronze {
    color: #aa7042;
}

span.twDB {
    font-size: 11px;
    padding-left: 5px;
}

.searchHelp {
    position: absolute;
    top: 44px;
    box-shadow: 6px 6px 5px 0px rgb(0 0 0 / 5%);
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled),
.nav-line-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid rgba(0, 0, 0, 0.25) !important;
}

.nav-line-tabs .nav-item .nav-link {
    margin: 0 0.5rem;
}

.searchHelpClose {
    position: absolute;
    right: 8px;
    top: 8px;
}

.searchHelpClose i {
    font-size: 20px;
    cursor: pointer;
}

.searchHelpClose i:hover {
    color: #000;
}


/* CSS FROM M Start */
.header-bg {
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: url("../media/misc/header-bg.png"); */
}

.page-bg {
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    /* background-image: url("../media/misc/page-bg.jpg"); */
}

.modal-fullscreen .modal-content {
    border-radius: 24px;
}

.modal-fullscreen .modal-content .modal-header {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
}

@media (min-width: 992px) {

    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
        padding: 0 30px;
    }

    .wrapper {
        transition: padding-left ease;
        padding-top: 75px;
        padding-left: 320px;
    }
}

@media (max-width: 991.98px) {

    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
        max-width: none;
        padding: 0 15px;
    }

    .header-tablet-and-mobile-fixed .wrapper {
        padding-top: 60px;
    }
}

.header {
    display: flex;
    align-items: stretch;
}

.header .header-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .container-fluid,
.header .container-sm,
.header .container-md,
.header .container-lg,
.header .container-xl,
.header .container,
.header .container-xxl {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.header {
    position: fixed;
    height: 75px;
    z-index: 100;
    background-color: #fff;
    top: 0;
    right: 0;
    left: 0;
    box-shadow: 0px 1px 2px 0 rgb(0 0 0 / 10%);
    border-top: 5px solid #72B261;
}


@media (max-width: 991.98px) {
    .header {
        height: 60px;
    }

    .header-tablet-and-mobile-fixed .header {
        position: fixed;
        height: 60px;
        z-index: 100;
        top: 0;
        right: 0;
        left: 0;
    }
}

@media (min-width: 992px) {
    .topbar .header-search form .form-control {
        border-radius: 0.475rem;
        background-color: rgba(255, 255, 255, 0.1) !important;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
        color: #ffffff !important;
    }

    .topbar .header-search form .form-control::placeholder {
        color: rgba(255, 255, 255, 0.75);
    }

    .topbar .header-search form .form-control::-moz-placeholder {
        color: rgba(255, 255, 255, 0.75);
        opacity: 1;
    }

    .topbar .header-search form .svg-icon {
        color: #ffffff;
    }

    .topbar .header-search form .spinner-border {
        color: rgba(255, 255, 255, 0.75) !important;
    }
}

@media (max-width: 991.98px) {
    .topbar .btn {
        height: 33px;
        display: flex;
        align-items: center;
    }

    .topbar .btn-icon {
        width: 33px;
        height: 33px;
    }

    .topbar .header-search form .form-control {
        height: 33px;
    }
}

.aside {
    position: fixed;
    top: 105px;
    bottom: 30px;
    left: 30px;
    z-index: 100;
    overflow: hidden;
    width: 280px;
}

.aside {
    display: flex;
    flex-direction: column;
    background-color: var(--kt-aside-bg-color);
    padding: 0;
}

@media (min-width: 992px) {
    .aside {
        position: fixed;
        top: 105px;
        bottom: 30px;
        left: 30px;
        z-index: 100;
        overflow: hidden;
        width: 250px;
    }

    .content {
        padding: 30px 0;
    }

    .app-footer {
        height: var(--bs-app-footer-height);
    }
}

.aside {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 0;
}

.brandName {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bolder;
}

.brandName img {
    max-width: 300px;
}

.asideFirstButton a .menu-arrow i {
    line-height: normal !important;
}

div#kt_aside_menu_wrapper {
    height: auto !important;
}

.aside-menu .menu-item .menu-link .menu-title {
    font-size: 14px;
    color: #222;
}

.aside-menu .menu-heading {
    font-size: 16px !important;
    font-weight: bold !important;
    color: #222 !important;
}

.asideFirstButton a {
    color: #fff !important;
    font-size: 16px !important;
    text-transform: uppercase;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* .aside-menu .menu-link span,
.aside-menu .menu-link span i {
    color: #222 !important;
} */

.aside-menu .menu-link:hover span,
.aside-menu .menu-link:hover span i {
    color: var(--webgOrange) !important;
}

.aside-menu .menu-item .menu-content {
    padding: 0.65rem 1.6rem !important;
}

.app-footer {
    transition: left 0.3s ease, right 0.3s ease;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-top: 1px solid #f1f1f1;
    box-shadow: 0px -1px 2px 0 rgb(0 0 0 / 10%);
}

.app-footer li.menu-item a {
    position: relative;
    color: #222 !important;
    font-weight: 500;
    padding: 8px 18px !important;
}

.app-footer li.menu-item:last-child a {
    padding-right: 0px !important;
}

.app-footer li.menu-item:last-child a::before {
    height: 16px;
    background: #222;
    width: 1px;
    position: absolute;
    content: "";
    left: 0;
}

.customSearchBtn {
    width: 34px !important;
    height: 34px !important;
    right: 6px;
    border-radius: 5px !important;
    top: calc(50% - 17px) !important;
    position: absolute !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 5 !important;
}

.border,
.border-bottom {
    border-color: var(--webgBorderLight) !important;
}

.customSearchBox .border,
.topDropdownList .select2-container--bootstrap5 .select2-selection {
    border-color: #c9cccf !important;
}

.bg-gray-400 {
    background-color: currentColor !important;
}

.customSearchBox input.form-control.border {
    font-weight: 400;
    color: #202223;
    min-width: 300px;
}

.topDropdownList .select2-container--bootstrap5 .select2-selection {
    min-height: 44px;
}

.topDropdownList .select2-container--bootstrap5 .select2-selection {
    min-height: 44px;
    font-size: 14px;
    font-weight: 400;
    background-color: #f5f5f5;
}

.topDropdownList .select2-container--bootstrap5 .select2-selection span {
    color: var(--webgColorDark)
}

.customSearchBox .input-group>button.btn-outline-secondary {
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    color: var(--webgColorDark) !important;
}

.btn,
.form-control,
.form-select {
    border-radius: 5px !important;
}

.customSearchBtn i {
    font-size: 20px !important;
    transform: rotate(270deg);
}

.btn {
    font-size: 14px !important;
    font-weight: 400;
}

.form-check.form-check-solid .form-check-input {
    border-color: #8c9196 !important;
    background-color: transparent;
    border-width: 2px !important;
    border: solid;
}

.form-check.form-check-solid .form-check-input:checked {
    border-color: #72B261 !important;
}

.form-check.form-check-solid .form-check-input:checked {
    background-color: #72B261 !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #f9f9f9;
}

table.table>thead {
    vertical-align: middle;
}

table.table>thead .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.desktopButtonsTD {
    display: flex;
    gap: 5px;
    justify-content: end;
}

.exportIcons ul li {
    list-style: none;
    cursor: pointer;
}

.exportIcons ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
}

.tablePaginationMain {
    display: flex;
    align-items: center;
    gap: 15px;
}

.tablePagination ul.pagination li {
    border: 1px solid var(--webgBorderDark);
}

.tablePagination ul.pagination li,
.tablePagination ul.pagination li a {
    font-size: 14px;
    font-weight: 400;
}

.tablePagination ul.pagination li {
    margin: 0;
    border-right: 0;
}

.tablePagination ul.pagination li:last-child {
    border: 1px solid var(--webgBorderDark);
}

.tablePagination ul.pagination li .page-link {
    border-radius: 0;
}

.tablePagination ul.pagination li.page-item.active {
    border-color: #72B261 !important;
}

.tablePagination ul.pagination li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden !important;
}

.tablePagination ul.pagination li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.card .card-footer {
    padding: 1.5rem 2.05rem;
}

a:hover {
    color: var(--bs-primary);
    text-decoration: underline;
}

.tableInput .input-group {
    flex-wrap: nowrap;
}

.tableInput {
    min-width: 150px;
}

.tableInput input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 36px !important;
}

.tableInput label {
    line-height: 9px;
    font-size: 14px;
}

.tableInput .input-group-text {
    height: 36px;
    padding: 10px;
    border-radius: 5px !important;
    margin-right: -3px;
    z-index: 9;
    font-size: 14px;
}

.tableInputBox input.textRight {
    text-align: right !important;
}

.g-10,
.gx-10 {
    --bs-gutter-x: 7.5rem !important;
}

.iconOnInput i {
    top: 4px;
    background: var(--bs-primary);
    color: #fff;
    font-size: 22px;
    height: 35px;
    width: 35px;
    display: flex;
    border-radius: 5px;
    right: 4px;
    align-items: center;
    justify-content: center;
}

.btn.btn-primary {
    background-color: #72B261;
}

.active span,
.active span i {
    color: #72B261 !important;
}

.drop_down_icon i {
    font-size: 1rem !important;
}

@media (min-width: 1200px) {
    i.fs-1 {
        font-size: 1.7rem !important;
    }
}

#root .fs-6 {
    font-size: 1.075rem !important
}

select {
    min-width: max-content;
}

.card .card-header .product {
    flex-direction: column;
}

.asideFirstButton .menu-item .menu-link {
    padding: .25rem 0.5rem !important;
}

.customSearchBox button {
    border-radius: 5px 0px 0px 5px !important
}

.customSearchBox input {
    border-radius: 5px !important
}

.card-header .card-title {
    gap: 1rem !important;
}

.btn.customSearchBtn {
    border-radius: 5px !important;
}

.topDropdownList .form-select {
    background-color: #f5f5f5 !important;
}

#root .btn {
    padding: calc(0.55rem + 1px) calc(1rem + 1px) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

thead tr th {
    vertical-align: top;
}

.product_detail td {
    font-size: 14px;
}

#root .btn.btn-color-dark i {
    color: #181c32 !important;
}

.active,
.active .Polaris-Icon--colorBase svg {
    fill: #72B261 !important;
}

.sideimg {
    width: 24px;
    height: 25px;
    background-color: transparent;
}

div#kt_aside {
    display: flex;
    background: white;
    top: 60px;
    left: 0;
    padding: 30px 20px;
    border-right: 1px solid #dddddd9c;
    max-height: 87vh;
    width: 100%;
    max-width: 300px;
}


.partner_message{
    margin-top: 20px;
    height: 200px;
  }
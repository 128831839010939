body {
  margin: 0;
  font-family: Poppins, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
select.btn{
  color: black !important;
}
select.btn option{
  text-align: left;
}
.input-group>.form-control:focus, .input-group>.form-floating:focus-within, .input-group>.form-select:focus{
  z-index:auto !important;
}
.position-relative{
  height: 100%;
}
.card .card-header .card-title{
  align-items: stretch;
}
.dropdown-menu{
  background: #e4e6ef !important;
}
.dropdown-menu li{
  padding: 5px 0px;
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.041);
  
}
li a{
  color: black;
  padding: 0px 10px;
  width: 100%;
    display: block;
}
.desktopButtonsTD a:hover i {
  color:#72B261 !important;
}

.menu-sub .menu-link{
  padding-left: 40px !important;
}

.btn.btn-active-color-danger:hover:not(.btn-active) i{
  color: #f1416c !important;
}
.page-link{
  color:#5e6278 !important;
}
.page-item.disabled .page-link {
  color: #b5b5c3 !important;
  pointer-events: none;
  background-color: transparent;
  border-color: transparent;
}
thead tr{
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}
tbody tr{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
tbody td{
  border-bottom: none !important;  
}
.login_section{
  height: 100vh;
  background-color: #fff;
}
.login_section .shadow-sm{
  box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%) !important;
}
.login_section .rounded {
  border-radius: 0.65rem !important;
}
.login_section a{
  color: #72B261 !important;
}
.login_section .h1, .login_section h1 {
  font-size: 1.75rem
}
.login_section  .form-control-lg{
  padding: .825rem 1.5rem;
}
.dropdown{
  position: relative;
}
.btn i{
  margin-left: 5px;
}
#kt_header .dropdown-menu.show {
  display: block;
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: white !important;
  border: none;
  width: 200px !important;
}
.header{
  padding: 20px;
}
.page{
  min-height: 94vh;
  background-color: #fefffe;
}
.menu-item span{
  font-size: 13px;
}
.menu-item i{
  font-size: 1.4rem  !important;
}
i.fa-warehouse{
  font-size: 1.2rem !important;
}
.asideFirstButton .menu-title{
  font-size: 16px !important;
}
.menu-bullet i{
  font-size: 13px !important;
}
.asideFirstButton .menu-item .menu-link .menu-arrow{
  width: 1.5rem;
  height: 1.5rem;
}
.asideFirstButton .menu-arrow i{
  font-size: 16px !important;
}
#kt_content_container{
  padding: 0px 30px;
}
.asideFirstButton.rounded-2 {
  border-radius: 0.65rem !important;
}

.page-item:not(:first-child) .page-link{
  margin-left: 0px !important;
}

.pagination .page-link{
  border: none !important;
}

.login_section .link-primary:focus, .login_section .link-primary:hover {
  color: #72B261 !important;
  filter: brightness(0.9);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #f5f8fabf !important;
}

#root .fw-bold{
  font-weight: 600 !important;
}
#root .fs-3 {
  font-size: 1.35rem !important;
}

#root .py-4 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
#root .py-4 h3{
  font-size: 21px;
}
.upload__inputfile{
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.text-hover-primary:hover{
  color: #72B261 !important;
}

.createTransfer .iconOnInput i{
  background-color: #72B261 !important;
  color : white !important;
  font-size: 18px !important;
}

#edit_product .form-control, #edit_product .form-select ,.createTransfer .form-control , .createTransfer .form-select{ 
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  appearance: none;
  border-radius: 0.65rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.carousel button{
  font-family: "Line Awesome Free";
  font-weight: 900;
  height: 100px;
  color:black !important;
  opacity: 1;
}

 #root .carousel .control-arrow,  #root.carousel.carousel-slider .control-arrow{
  bottom: 0px !important;
  top: auto !important;
  display: flex;
  align-items: flex-end;
  opacity: 1;
}
.control-prev:before{
  content: '\f359' !important;
  border: none !important;
}
.control-next:before{
  content: "\f35a" !important;
  border: none !important;
}


.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0,0,0,0.0) !important;
}
#root .pcCaption strong{
  font-size: 16.25px !important;
}

#root .page-link:focus {
  z-index: 3;
  color: #000;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #e9ecef;
}
#root .page-item.active .page-link:focus {
  z-index: 3;
  color: #fff !important;
  background-color: #72B261!important;
  border-color: transparent;
}

.dropdown-toggle::after{
  margin-left: 0.5em !important;
}

.btn-color-dark i {
  color: #181c32 ;
}
.customSearchBox select{
  border-radius: 5px 0px 0px 5px !important;
}
.customSearchBox input{
  border-radius: 0px 5px 5px 0px !important;
}
#root .customSearchBtn i{
  font-size: 18px !important;
}
#root .customSearchBtn{
    width: 30px !important;
    height: 30px !important;
    right: 3px;
    border-radius: 5px !important;
    top: calc(50% - 15px) !important;
    padding: 0px !important;
}

.card .card-header{
  min-height: 60px !important;
}

.warehouse td{
  display: flex;
  gap: 150px;
  border: none;
}
.vander_variants tr tr td , .vander_variants tr tr th , .vander_variants tr tr{
  vertical-align: top;
  border: 0px !important;
}
.vander_variants td .add_field i{
  margin-top:10px;
  font-weight: 700;
  color: #72B261;
  cursor: pointer;
}
.add_field i{
  color: #72B261 !important;
}
.vander_variants tr tr{
  display: flex;
    gap: 70px;
}
/* .table.gy-5 td, .table.gy-5 th{
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
} */
.mb-5 {
  margin-bottom: 1rem !important;
}
.card-footer .card-title{
  width: 330px;
}
.card-footer .card-title input{
  padding: 8.5px 10px !important;
  border-radius: 5px  !important;
}
select:focus{
  border-color: #e4e6ef !important;
}
#root i{
  color: #181c32;
}

#root .menu-arrow i{
  color: white;
}
#root .tableInput .input-group-text{
  padding: 5px !important;
}
.purchaseOrder .desktopButtonsTD .btn{
  width: 120px;
  background-color: black;
  padding: 8px !important;
}
.card-header i{
  color : white !important;
}

.input-group input{
  width: 15px;
  text-align: center;
  padding: 5px 0px !important;
 }
.input-group .btn{
 height: 100%;
}
.input-group .btn{
  height: 100%;
  border: 1px solid #e4e6ef !important;
  color: black;
 }
 .input-group .btn:hover{
  background-color: transparent;
  color:black
 }
 .input-group .btn:active{
  background-color: transparent !important;
  color:black
 }
 .input-group .btn:focus-visible{
  background-color: transparent !important;
  color:black
 }

 .form-control:focus{
  border: 1px solid #e4e6ef !important;
 }

 .createTransfer label{
  height: 100%;
  display: flex;
  align-items: center;
 }
 .customSearchBox .input-group .btn i{
  text-align: left !important;
  color: #fff !important;
 }
 .customSearchBox .input-group input{
  text-align: left !important;
  padding-left: 10px !important;
 }
 
 .card-title{
  padding: 5px 0px;
 }
 .topbar .btn:hover i{
  color: white !important;
 }
 .topbar .btn{
  display: flex;
  align-items: center;
 }
.topbar #myDrop{
  padding-top: 1rem !important;
  padding-bottom : 1rem !important;
}


 .table.gs-7 td:last-child, .table.gs-7 th:last-child {
  padding-right: 8em !important;
}
.btn-icon{
  display: flex;
  align-items: center;
}
.form-floating .form-control{
  min-width: 125px;
} 
.card-title select{
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.iconOnInput i{
  transform: rotateY(180deg);
}

.createTransfer .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: transparent !important;
}
.createTransfer .table-striped>tbody>tr:nth-of-type(even)>* td span {
  color : inherit !important;
}
.createTransfer td {
  color: #212529 !important;
}
.readonly{
  background: #f5f8fa !important;
}

.card-body.store_table_body.store_table_section .Polaris-DataTable__Cell:last-child {
  min-width: 65px !important;
  text-align: end !important;
}


.Polaris-DataTable__Cell:nth-child(5) {
  min-width: 150px !important;
  max-width: 150px;
  text-align: start !important;
}
.Polaris-DataTable__Cell:nth-child(6){
  min-width: 115px !important;
  padding-right:13px !important;
}
.Polaris-DataTable__Cell:nth-child(7){
  min-width: 65px !important;
}
.Polaris-DataTable__Cell:nth-child(8){
  min-width: 100px !important;
}
.Polaris-DataTable__Cell:nth-child(9){
  min-width: 100px !important;
}
.app-footer .app-container{
  padding: 10px 30px 5px 30px !important;
}


div.dataTables_wrapper {
    width: 800px;
    margin: 0 auto;
}

tr .fix:first-child {
  position:sticky;
  left:0px;
}
tr .fix:nth-child(2) {
  position:sticky;
  left:61.75px;
}
.myDrop{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}





.Polaris-Page{
  width: 100%; 
  max-width: 100% !important;
}
.Polaris-DataTable__Table{
  overflow-x: scroll;
}
.Polaris-DataTable__Cell:nth-child(2){
  /* min-width: 80px; */
  font-size: 13.975px;
}

.Polaris-DataTable__Cell:nth-child(3){
  text-align: start !important;
  min-width: 110px;
  white-space : normal !important;
}


.order_table  .Polaris-DataTable__Cell:nth-child(3){
  text-align: start !important;
  min-width: 110px;
  white-space : normal !important;
}
.Polaris-DataTable__Cell img{
 width: 60px !important;
 height: 60px;
 border-radius: 10px;
}
.Polaris-DataTable__FixedFirstColumn{
  box-shadow: 10px 0 9px 0px rgb(0 0 0 / 5%);
}
.Polaris-DataTable__Navigation{
  display: none !important;
}
.Polaris-Page__Content{
  padding: 0px !important;
}
.Polaris-Page__Content th{
  padding-bottom: 1rem!important;
  padding-top: 1.75rem!important;
  vertical-align: middle !important;
}
.Polaris-Page__Content td{
  padding-bottom: 1.75rem!important;
  padding-top: 1.75rem!important;
  vertical-align: middle !important;
}
.Polaris-Page__Content thead th{
 
  font-weight: 600 !important;
}
.Polaris-DataTable__Cell{
  text-align: center !important;
}
.Polaris-DataTable__Cell--header{
  min-width:125px;
}
.Polaris-DataTable__Cell--header:first-child{
  min-width: min-content;
}

.table-striped>tbody>tr:nth-of-type(odd)>* td:first-child{
  background-color: #f5f8fa !important;
  box-shadow: 10px 0 9px 0px rgb(0 0 0 / 5%);
}
.table-striped>tbody>tr:nth-of-type(even)>* td:first-child{
  background-color: #fff !important;
  box-shadow: 10px 0 9px 0px rgb(0 0 0 / 5%);

}
.vander_variants th:first-child{
  box-shadow: 10px 0 9px 0px rgb(0 0 0 / 5%);
  background-color: #fff;
}

.vander_variants th:first-child, .vander_variants td:first-child{
  position:sticky;
  left:0;
}


.sales_rev select , .sales_unit select{
  padding:4px;
  border: 1px solid rgba(170, 170, 170, 0.315);
  background-color: transparent;
  font-size: 90%;
}
.sales_rev select:focus , .sales_unit select:focus{
  outline:0px;
}
.Polaris-Page__Content thead th{
  vertical-align: top !important;
 }


 .card-footer .card-title{
  margin-bottom: 4rem;
 }

 .footer_last_row{
  display: flex;
  justify-content: space-between;
 }

td .boxIcon{
  width: 120px;
  font-size: 90%;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.fa-lightbulb-on:before{
  content: "\f672"
}

#root .form-select{
  background-image: url("/src/assets/crImages/down.png") !important;
  background-size: 13px 11px !important;
}
#root .menu-item .menu-link .menu-arrow:after{
  background-image: url("/src/assets/crImages/arrow_down.png") !important;
  transform: rotateZ(0deg);
  transition: transform .3s ease;
  background-size:13px 13px;
  background-position: 0px;
}
.asideFirstButton .menu-arrow:after{
  background-image: none !important;
  background-size: 0px !important;
}
#root .menu-item .menu-link .menu-arrow{
  width: 1.8rem;
}

.Polaris-Page{
  padding: 0px !important;
}

.btn.btn-color-primary{
   color: #72B261;
}
.btn.btn-color-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: #72B261 !important;
}

.form-select option{
  width: 200px;
  max-width: 200px;

}

#kt_aside {
  transition: all ease-in-out 1s;
}


.form-check:not(.form-switch) .form-check-input[type=checkbox] {
  background-size: 100% 100%;
}


.Polaris-Button {
  background: #72B261!important;
  color: #fff !important;
  border: #72B261!important;
}
.text-left {
  text-align: left;
}



.form-check-input:checked{
  background-color: #72B261 !important;
  border-color: #72B261 !important;
}
.form-check-input:focus {
    border-color: #00000040 !important;
    outline: 0;
    box-shadow: none !important ;
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
  border:none !important;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}






/*  Responsiveness Of the Design */





@media (max-width: 1375px){
  .card-header .card-title{
    flex-direction: column;
  }
  .card-title1 {
    margin-top: 15px;
    float: right;
    width: 50%;
  }
  #root .customSearchBtn{
    width: 28px !important;
    height: 28px !important;
    right: -8px;
    top: calc(50% - 14px) !important;
  }
  .page .fs-1 {
    font-size: calc(1.375rem + 0.5vw)!important;
}
.form-select{
  width: 170px !important;
  max-width:170px !important;
}

}
@media (max-width:1090px) {
  .topDropdownList {
    flex-wrap: wrap;
  }
  
}
@media (max-width:992px) {
  .page #kt_content{
    margin-top: 60px;
  }
  
  #kt_aside{
    display: none;
  }
  #kt_aside .card .card-header .card-toolbar {
    align-content: flex-start;
  }
  .topDropdownList {
    flex-wrap: wrap;
  }
}

 @media (max-width: 380px){
.container-fluid .brandName {
    font-size: 19px !important;
}
 }
@media (min-width: 280px) and (max-width: 767px){
  #setting-123 form.d-flex.align-items-start.gap-3.flex-column{
    width: 100% !important;
  }
  
  #setting-123 .card.border .switch_group {
    width: 100% !important;
}
  tbody tr td:first-child{
    padding-left : 1.2rem;
  }
  .customSearchBox input.form-control.border{
    min-width: auto !important;
  }
  .topDropdownList .form-select{
    max-width: 100% !important;
    width: 100% !important;
  }
  .card-footer .tablePaginationMain{
    flex-direction: column;
  }
.card .card-header:nth-child(2).py-4{
  order: -1;
}
.card .card-header:nth-child(2) .listingCounter{
  padding-bottom: 1rem !important;
}
.card .card-header  .iconsexport{
  padding: 1rem 0px !important;
}
.dataTables_info{
  display: inline;
}
.card .card-header:nth-child(2) .dots{
  margin-left:10px;
  text-align: right;
}
.card .card-header:nth-child(2) .dots span{
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #181c32;
  display: inline-block;
  margin:1px;
}
.card .card-header .iconsexport{
  display: flex;
  margin-left:10px !important;
  width: 100%;
  justify-content: space-between;
} 
#filter_field_1{
  display: none;
  flex-direction: column;
}

.listingCounter.d-flex.align-items-center {
  flex-wrap: wrap;
}
.col-sm-12.col-md-4.d-flex.align-items-center.justify-content-center.justify-content-md-end , .col-sm-12.col-md-4.d-flex.align-items-center.justify-content-around.justify-content-md-end{
justify-content: space-between !important;
display: flex;
flex-wrap: wrap;
}
.card-toolbar .button_group{
  display: flex;
    align-items: center;
    margin: 0.5rem 0;
    flex-wrap: wrap;
    gap:10px;
}
.card-toolbar .button_group .btn{
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.footer_last_row{
  align-items: flex-end;
}
.searchListing.justify-content-center{
  flex-wrap: wrap;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
tbody td:first-child, thead th:first-child{
  padding-left: 0.75rem !important;
} 
.customSearchBox{
  width: 100%;
}
.customSearchBox .input-group{
  display: flex;
  flex-wrap: nowrap !important;
}
}


@media (max-width: 450px){
  .brandName{
    font-size: 24px !important; 
  }
}

.card-header button{
  width: max-content;
}
.desktopButtonsTD .btn{
  width: max-content !important;
}

.max-w-300{
  max-width: 300px;
}


#setting-123  .form-group.d-flex.text-left.mb-2 {
  display: flex !important;
  justify-content: space-between;
  /* margin: 0; */
  /* padding: 0; */
  align-items: center;
  width: 100%;
}

#setting-123  input.form-control {
  height: 40px;
  width: 75% !important;
}

#setting-123  .card.border label {
  font-size: 14px;
  font-size: 14px;
  color: #222;
  font-weight: 400;
}
#setting-123  .card.border input.Polaris-Button.mt-3 {
  margin-left: auto;
  width: 90px;
}
#setting-123  .card.border .switch_group {
  padding: 22px;
  width: 75%;
}

#setting-123  button.Polaris-Button.mt-3 {
  margin-left: 11%;
}
#setting-123  .form-check.form-switch {
  padding: 10px 0px;

  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-direction: row-reverse;
  align-items: center;
}
/* #setting-123  input#flexSwitchCheckDefault {
  font-size: 22px !important;
} */
#setting-123  form.d-flex.align-items-start.gap-3.flex-column {
  width: 75%;
  padding: 22px;
  /* box-shadow: 1px 2px 10px #80808038;
  border-radius: 10px;
  border: 1px solid #80808030; */
}

button.Polaris-Button.mt-3 {
  margin-left: auto;
  margin-top: 30px;
  display: block;
  width: 90px;
}

.Polaris-DataTable__TableRow .image_image{
  width:100%;
  text-align: left;
}
.previous{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0px !important;
}
.pagination li{
    border: 1px solid #c9cccf;
    border-right:0px;
}
.disabled a{
  color: #b5b5c3 !important;
  pointer-events: none;
}
.pagination li.active{
  background-color: #72B261;
  border: 1px solid #72B261;
  color:white;
}
.card-footer{
  display:flex;
  align-items: center;
}
.next{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 1px solid #c9cccf !important;
}
.pagination a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.65rem;
  height: 2.5rem;
  min-width: 2.5rem;
  font-weight: 500;
  font-size: 1.075rem;
}


.Toastify__progress-bar-theme--light {
  background: #72B261 !important;
}

.btn:active, .Polaris-Button:active{
  background: #ff852acf !important;
  box-shadow: 0 5px #ff862a3f;
  transform: translateY(2px);
}
.pagination a:hover{
  color: #ff852acf;
}

.card-footer {
    justify-content: space-between !important;
}

.setting-btn {
    height: 36px;
}

.mng-hig {
    height: 40px;
}

.menu-item .menu-link {
    padding: 10px;

}

.menu-active-bg .menu-item .menu-link.active {
    background-color: #fff !important;
}

/* button.Polaris-Button.ms-auto.setting-btn.save-btn{
    margin-left: 37% !important;
    width: 13%;
    margin-top: 20px;
} */
button.Polaris-Button.ms-auto.setting-btn.save-btn {
    margin-right: 1.5% !important;
}

button.Polaris-Button.mt-3.ms-auto.setting-diconbtn {
    background-color: #fff !important;
    color: var(--p-text) !important;
    border: var(--p-border-width-1) solid var(--p-border-neutral-subdued) !important;
    width: 95px;
    height: 36px;
    /* background-color: #f2eded !important;
    color: black !important; */
}

.templo1 div {
    padding: 10px 0;
}

.templo1>div>div {
    width: 30% !important;
    /* height: 220px; */
    height: auto;
}

.templo1 div .mokbtn {
    background: #72B261 !important;
    color: #fff;
    border: #72B261 !important;
    border-radius: 4px;
}

.templo1 div .singledeletey {
    background: #72B261 !important;
    color: #fff;
    border: #72B261 !important;
    border-radius: 4px;
    margin-left: 5px;
}

.question_icons {
    text-align: center;
}

.question_icons img {
    width: 18%
}

.del-modal-h1 {
    text-align: center;
    /* justify-content: space-between; */
    font-size: 15px;
    width: 99%;
}

.del-modal-right-section {
    text-align: center;
}

.singledeleten {
    padding: 6px 12px;
    color: #fff !important;
    background-color: #72B261;
    border-color: #72B261 !important;
    border-radius: 4px;
}

.singledeletey {
    margin-top: 10px;
    padding: 8px 12px;
    color: white;
}

.templo1 div .singledeletey {
    background: #72B261 !important;
    color: #fff;
    border: #72B261 !important;
    border-radius: 4px;
    margin-left: 5px;
}

th .aa {
    margin-left: 20px !important;
    border-color: #8c9196 !important;
    background-color: transparent;
    border-width: 2px !important;
    border: solid;
    border-radius: 4px !important;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: inherit;
    font-size: 14px;
}

/* .form-check-input[type=checkbox] {
    font-size: 17px;
    border-radius: 0.25em;
    background-size: 100% 100%;
} */

.pro .form-check-input[type=checkbox] {
    font-size: 20px;
    border-radius: 4px;
    display: flex !important;
    /* margin: 0 !important; */
    /* margin-left: 24px !important; */
}

.prosrdiv {
    margin-left: 7px;
    font-size: 14px;
}

.prosrdiv1 {
    margin-left: 17px;
    font-size: 14px;
}

.indexdiv {
    width: 50%;
    font-size: 15px;
}

.schange {
    margin-top: 11px !important;
    margin-left: 0 !important;
    text-align: end !important;
}

/* .schange{
    margin-top: 11px;
    margin-left: 35px;
} */
.schange1 {
    margin-top: 11px;
}

.Polaris-Select {
    height: 37px;
}

.Polaris-Select__SelectedOption {
    font-size: 15px;
    margin-top: 5px;
}

div#filter_field_1 {
    border-bottom: 1px solid #e6dbdb;
}

.card-title {
    margin-left: 20px;
    margin-top: 10px;
}

/* .form-check.form-check-sm.form-check-custom.form-check-solid td{
    margin-left: 20px;
} */

.tabdiv {
    display: flex;
}

.tadiv td:nth-child(1) {
    margin-left: 15px;
    font-size: 15px;
}

.tadiv td:nth-child(2) {
    margin-left: 21%;
}

.tadiv td:nth-child(3) {
    margin-left: 28%;
}

.tabdiv td:nth-child(1) {
    margin-left: 0%;
    width: 30%;
}

.tabdiv td:nth-child(2) {
    margin-left: 18%;
}

.tabdiv td:nth-child(3) {
    margin-left: 24%;
}

.toggle {
    cursor: pointer;
    display: inline-block;
}

.toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 58px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
}

.toggle-switch:before,
.toggle-switch:after {
    content: "";
}

.toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-checkbox:checked+.toggle-switch {
    background: #72B261;
}

.toggle-checkbox:checked+.toggle-switch:before {
    left: 30px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
}

#setting-123 .form-check.form-switch {
    margin-left: 39px !important;

}

span.Polaris-Icon.Polaris-Icon--colorBase.Polaris-Icon--applyColor {
    width: 50px;
    height: 22px;

}

.Polaris-Page__Content thead th {
    text-align: start !important;
    vertical-align: top !important;
}

.Polaris-Page__Content td {
    text-align: start !important;

}

.Polaris-Page__Content td td {
    height: 100px;
    vertical-align: middle !important;
    padding-top: 0.5px !important;
    padding-bottom: 0.5px !important;
}

.card-title1 {
    margin-top: 15px;
    float: right;
    width: 80%;
}

.text-end {
    margin-right: 25px !important;
}

.Polaris-DataTable__Table {
    overflow-x: auto;
    min-width: 1400px;
}

/* ======================================= */


.store_section_065 td {
    padding: 13px !important;
    margin: 0;
    height: auto !important;
}

.order_table_069 td {
    padding: 13px !important;
    margin: 0;
    height: auto !important;
}

.card.bstore.store_section_065 td button {
    border: none !important;
    font-size: 14px !important;
    padding: 0px !important;
    margin: 0 !important;
}

button.action_view svg {
    fill: #72B261;
    width: 20px;
}

button.action_view {
    background: transparent;
    cursor: pointer;
}

input.form-control.border.mng-hig {
    padding: 10px !important;
}

button.search_btn {
    border: none;
    position: absolute;
    right: 0;
    z-index: 9;
    padding: 10px;
    background: transparent !important;
}

.card-body.order_table_069 td:nth-child(5) {
    text-align: end !important;
}

.card-body.order_table_069 th:nth-child(5) {
    min-width: 125px !important;
    text-align: end !important;
}

.card-body.order_table_069 th:first-child {
    text-align: start !important;
}

input.form-control.bstore.mng-hig {
    width: 100%;
    padding: 10px 40px 10px 10px !important;
}



.arrow-btn {
    border: 2px solid #72B261 !important;
}

.arrow-btn:hover i {
    color: white !important;
}

h3.text-dark.fw-bold.my-1 a {
    background: #72B261;
    padding: 4px 4px;
    height: 25px;
    width: 25px !important;
    border-radius: 4px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
th.Polaris-DataTable__Cell.Polaris-DataTable__Cell--verticalAlignTop.Polaris-DataTable__Cell--firstColumn {
    text-align: start !important;
}

a.brandLogo img {
    width: 200px;
}

img.User_icon {
    width: 20px;
    margin-right: 10px;
}

.btn.btn-borderless.btn-active-primary.bg-white.bg-opacity-10:hover {
    background: transparent !important;
    color: #212529 !important;
}

.btn.btn-borderless.btn-active-primary.bg-white.bg-opacity-10:hover i {

    color: #212529 !important;
}

/* .Polaris-DataTable__Table  th {
    min-width: 150px;
} */


div#myDrop a {
    padding: 0;
    display: inline !important;
    text-align: start !important;
    ;
}


/* new css */

.Store-content-text p {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #212222;
    line-height: 24px;
}

.Store-content-text p strong {
    color: #72B261;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.profile_section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.Store-content-text img {
    MAX-WIDTH: 50PX;
    padding: 12px;
    background: #72b2613b;
    display: inline-block;
    border-radius: 10px;
    OBJECT-FIT: cover;
    HEIGHT: AUTO;
    width: 100%;
}

.Store-content-text {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

p.Partner_text {
    font-size: 14px;
    font-weight: 400;
}

#root .py-4 h3 {
    font-size: 20px !important;
    align-items: center;
    display: flex;
    gap: 10px;
}





@media (max-width: 1375px) {



    .app-container.container-fluid.d-flex.flex-column.flex-md-row.flex-center.flex-md-stack.py-3 {
        display: flex;
        flex-direction: row !important;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
    }

    .profile_section {
        display: grid;
        grid-template-columns: 1fr;
    }

    .card-title1 .Polaris-Grid {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100% !important;
    }

    .card-title1 {
        margin-top: 15px;
        float: inline-start;
        width: 350px !important;
    }


    .card-title1 {
        margin-top: 15px;
        width: 100% !important;
    }
}

@media (min-width: 992px) {


    .card-title1 {
        margin-top: 15px;
        float: right;
        width: 80% !important;
        max-width: 250px;
    }
}


@media (max-width: 992px) {

    div#kt_aside {
        display: none;
    }
}



@media (max-width: 767px) {


    .app-container.container-fluid.d-flex.flex-column.flex-md-row.flex-center.flex-md-stack.py-3 {

        flex-direction: column !important;

    }


    h3.text-dark.fw-bold.my-1 {
        display: block;
        width: 100%;
        padding-top: 10px;
    }

    .col-sm-12.col-md-4.d-flex.align-items-center.justify-content-center.justify-content-md-end {
        display: grid !important;
        grid-template-columns: 1fr 100px !important;
    }

    .card-footer {
        flex-direction: column;
        gap: 20px;
        padding: 10px !important;
    }

    .header {
        padding: 10px;
    }

}

.search-icon {
    transform: scaleX(-1);
}


button.action_view {
    color: #72b261;
}



.activeStatus {
    color: #3c9420;
    background: #c7e0cc;
    border-radius: 23px;
    padding: 5px;
    text-align: center;
}

.captureStatus {
    color: orange;
    background: #ffedcc;
    border-radius: 23px;
    padding: 5px;
    text-align: center;

}


.popup {
    box-shadow: 0px 0px 9px 0px #888888;
    padding: 10px;
    max-width: 400px;
    margin-left: 150px;
}

.popup table{
    width: 100%;
}
.card-header.bstore-bottom {
    padding: 20px;
}
.card-body.store_table_body {
    padding: 20px;
    border: 1px solid #dddddda6;
}

.card-body.store_table_body .Polaris-Card {
    box-shadow: none !important;
}

.card-body.store_table_body .Polaris-Card__Section {
    padding: 0;
}
.card.bstore.store_section_065 {
    border: 1px solid #dddddd9c;
    background: white;
    box-shadow: 0px 0px 10px #ddddddb0;
}

.aside-menu.bg-white.border.rounded-2 {
    border: 1px solid #ddddddba  !important;
}
.menu.menu-column.menu-rounded.menu-sub-indention.menu-active-bg .menu-item{
    border-bottom: 1px solid #ddddddba;
}



.order_table .Polaris-DataTable__Cell:nth-child(9) {
    min-width: 100px !important;
    text-align: end !important;
}


.eye-icon {
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 38px;
    right: 20px;
    max-width: 20px;
}
.fv-row.mb-10 {
    position: relative;
}

button#kt_sign_in_submit {
    height: 48px;
}

span.indicator-label {
    font-size: 16px;
    font-weight: 500;
}


.form-control.form-control-solid {
    border-color: #ddddddc4 !important;
    height: 48px;
}


.content.d-flex.flex-column.flex-column-fluid.create_partner_section #kt_content_container {
    padding: 0px 30px;
    max-width: 650px;
}

.content.d-flex.flex-column.flex-column-fluid.create_partner_section .card.bstore.store_section_065 .Polaris-Card {
    box-shadow: none !important;
    overflow: hidden;
}
li.previous {
    border: none !important;
}
li.next {
    border: none !important;
}

.pagination li.active {
    border-radius: 4px;
}
.menu.menu-column.menu-rounded.menu-sub-indention.menu-active-bg a.menu-link {
    padding: 16px 10px;
}

a.menu-link.active.active svg {
    fill: #72b261;
}

input.form-control.bstore.mng-hig {
    border: 1px solid #ddddddc4 !important;
}

p.no_data {
    display: block;
    text-align: center;
}
/* 6KrQGCChf0kA */


.comissionCard {
    display: block;
    margin-left: auto;
    margin-top: 20px;
    width: 100%;
    text-align: start;
    max-width: 350px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px #8c8c8c42;
    background-color: white;
    padding: 10px 0px;
  }
  
  .comissionCard h3 {
    color: #202223;
    font-size: 16px;
    font-weight: 400;
    padding: 14px 16px;
    text-align: center;
    display: flex;
    justify-content: space-between;
 
  }
  .comissionCard h3 span {
    font-weight: 600;
}